import React from "react";
import styled from "styled-components";

export default function Video() {
  return (
    <Section id="video">
      <div className="video-container">
        <iframe className="video" src="https://www.youtube.com/embed/zxQCqBh2djk?&mute=1" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen></iframe>
      </div>
    </Section>
  );
}

const Section = styled.section`


  .video-container {
    position: relative;
      width: 60%;
      height: 0;
      padding-bottom: 30.25%;
      height: 0;
      overflow: hidden;
      
      gap: 0.5rem;
      background-color:#f0f8ff;
      border-radius: 1rem;
      transition: 0.3s ease-in-out;
      margin-bottom: 20px;
      border: 25px solid #f0f8ff;
        margin: 0 auto;
    }

    .video-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
    }


    @media screen and (min-width: 280px) and (max-width: 980px) {
      .video-container {
        padding-bottom: 56.25%; /* 16:9 aspect ratio */
        width: 100%;
    }

    }
`;
