import React, { useState } from "react";
import styled from "styled-components";
import locations from "../assets/locations.json";
import attractions from "../assets/attractions.json";
import food from "../assets/food.json";
import loadging from "../assets/loadging.json";
import locations_world from "../assets/locations_world.json";
import { TbDiscount2 } from "react-icons/tb";
import DownloadAppBtn from "./DownloadAppBtn";

const list = [
  {
    name: 'טבע',
    data: locations,
    active: true
  },
  {
    name: 'אטרקציות',
    data: attractions
  },
  {
    name: 'אוכל',
    data: food
  },
  {
    name: 'לינה',
    data: loadging
  },
  {
    name: 'בעולם',
    data: locations_world
  }
]

export default function Recommend() {
  const [sections, setSections] = useState(list);
  const activeSection = sections.find(s => s.active)

  return (
    <Section id="recommend">
      <div className="title">
        <h2>יעדים מומלצים</h2>
      </div>
      <div className="packages">
        <ul>
          {sections.map((section, index) => {
            return (
              <li
                key={index.toString()}
                className={section.active ? "active" : ""}
                onClick={() => setSections(list.map(s => s.name == section.name ? { ...s, active: true } : { ...s, active: false }))}
              >
                {section.name}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="destinations">
        {activeSection.data.map((destination, index) => {
          const image = require(`../assets/${destination.images[0].fileName}`).default;
          return (
            <div className="destination" key={index.toString()}>
              <img src={image} alt="" className="img" />
              <h2>{destination.name}</h2>
              {
                destination.discount &&
                <div className="info">
                  <h4>קופון הטבה באפליקציה</h4>

                  <div className="services">
                    <TbDiscount2 size={22} />
                    <h4>{destination.discount}</h4>
                  </div>

                </div>
              }

            </div>
          );
        })}
      </div>

      <div style={{ textAlign: "center", marginTop: 20 }}>
        <h2 style={{ marginBottom: 10 }}>רוצה לראות מקומות נוספים? מאות יעדים מחכים לך באפליקציה</h2>
        <DownloadAppBtn showIcon={true} />
      </div>



    </Section>
  );
}

const Section = styled.section`
  padding: 2rem 0;
  .title {
    text-align: center;
  }
  .packages {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    ul {
      display: flex;
      list-style-type: none;
      width: max-content;
      list-style-type: none;
      cursor: pointer;
      li {
        color:black;
        padding: 1rem 2rem;
        border-bottom: 0.1rem solid  #08C988;
      &:hover {
          color #08C988;
        }
      }
   
      .active {
        color: #08C988;
        font-weight: bold;
        border-bottom: 0.5rem solid #08C988;
      }
    }
  }
  .destinations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    padding: 0 3rem;
    .destination {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      background-color:#f0f8ff;
      border-radius: 1rem;
      transition: 0.3s ease-in-out;
      &:hover {
        transform: translateX(0.4rem) translateY(-1rem);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
      img {
        width: 100%;
        height: 500px; /* Adjust the height as per your requirement */
        object-fit: cover;
        border-radius: 5px;
      }
      .info {
        display: flex;
        align-items: center;
        .services {
          display: flex;
          gap: 0.3rem;
          color: red;
          img {
            border-radius: 1rem;
            background-color: #4d2ddb84;
            width: 2rem;
            /* padding: 1rem; */
            padding: 0.3rem 0.4rem;
          }
        }
        display: flex;
        justify-content: space-between;
      }
      .distance {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 768px) {
    .img{
      height: 300px !important;
    }
    .packages {
      ul {
        li {
          padding: 0 0.5rem;
          font-size: 2vh;
          padding-bottom: 1rem;
        }
        .active {
          border-bottom-width: 0.3rem;
        }
      }
    }
    .destinations {
      grid-template-columns: 1fr;
      padding: 0;
    }
  }
`;
