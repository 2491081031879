import React from "react";
import styled from "styled-components";
import { RiHandCoinLine } from "react-icons/ri";
import { HiLocationMarker, HiUserGroup } from "react-icons/hi";
import { FaRegComments } from "react-icons/fa";

export default function Services() {
  const data = [
    {
      Icon: () => <HiLocationMarker size={30} />,
      title: "גישה למאות יעדים מדהימים",
      subTitle:
        "גלו מקומות חדשים בארץ וברחבי העולם.",
    },
    {
      Icon: () => <FaRegComments size={30} />,
      title: "המלצות ממטיילים מובילים",
      subTitle:
        "פוסטים, חוות דעת וביקורות של מבקרים",
    },
    {
      Icon: () => <RiHandCoinLine size={30} />,
      title: "צבירת נקודות",
      subTitle:
        "שיתוף מידע וחוויות יזכה אתכם בנקודות שתוכלו לממש להנחות ומתנות.",
    },
    {
      Icon: () => <HiUserGroup size={30} />,
      title: "קהילת מטיילים",
      subTitle:
        "הצטרפו לקהילת המטיילים שלנו ותוכלו לקבל המלצות ולשתף חוויות",
    },
  ];
  return (
    <Section id="services">

      {data.map((service, index) => {
        return (
          <div className="service" key={index.toString()}>
            <div className="icon">
              <service.Icon />
              <h3>{service.title}</h3>
            </div>

            <p>{service.subTitle}</p>
          </div>
        );
      })}
    </Section>
  );
}

const Section = styled.section`
  padding: 5rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  .service {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    background-color: aliceblue;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    &:hover {
      transform: translateX(0.4rem) translateY(-1rem);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .icon {
      flex-direction: row;
      display: flex;
      align-items: center;
      h3 {
        margin: 10px;
      }
      img {
        height: 2.4rem;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 720px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
