import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BsFillGiftFill } from "react-icons/bs";

export default function Gift() {

  const [showModal, setShowModal] = useState(false);
  const [giftCode, setGiftCode] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('Gift');
    const sources = ['ME', 'META'];

    if (sources.includes(source)) {
      switch (source) {
        case 'ME':
          setGiftCode("GiftMe")
          break;
        case 'META':
          setGiftCode("GiftMETA")
          break;
      }
    }

    const sharedId = urlParams.get('sharedId');
    if (sharedId) {
      localStorage.setItem('TripicSharedId', sharedId);
    }

  }, []);

  if (!giftCode) return null;

  return (
    <Section id="gift">
      <div className="giftBar" onClick={() => setShowModal(true)}>
        <span>לקבלת מתנת הצטרפות לחצו כאן</span>
        <BsFillGiftFill color="white" style={{ margin: 5 }} />

      </div>
      <div style={{ height: 50 }} />

      {
        showModal &&
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={() => setShowModal(false)} style={{ fontSize: 25 }}>&times;</span>

              <p> היי, קוד המתנה שלך הוא:</p>
              <h1>{giftCode}</h1>
              <p>
                לאחר הורדת האפליקציה צריך לבחור בקבלת מתנה דרך סרגל הצד ולהכניס את הקוד.
              </p>
            </div>
          </div>
        </div>
      }
    </Section>
  );
}

const Section = styled.section`
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 9999; /* Ensure the modal appears on top of other content */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modal-content {
  /* Additional styles for modal content */
}

.close {
  /* Styles for the close button */
}
.giftBar {
  span{
    color: white;
  }
    background-color: black;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    left: 0;
    right: 0;
    padding: 10px;
      }
`;
