import React from 'react'
import styled from "styled-components";
import { FaDownload } from "react-icons/fa";

export default function DownloadAppBtn({ style, classEvent, showIcon }) {
    const device = navigator.userAgent;

    const link = device.match(/iPhone|iPad/i)
        ? 'https://apps.apple.com/app/tripic-travelers-place/id1659214818?fbclid=IwAR3JiVWqAwqn5Vh01c09Bhn6JNF4VzbZqXS-lREBK_F9E8H1W--JTqIbVbI'
        : 'https://play.google.com/store/apps/details?id=com.goldenapps.tripic&fbclid=IwAR0PHMI7fxsfh0wiliUpzjMXYGIm7O1mvtMfWrVwxgdjb2ZhFeAF3gh8c-o';

    const openNewTab = () => {
        window.open(link, '_blank');
    };

    return (
        <StyledButton className={classEvent} style={style} onClick={openNewTab}>להורדת האפליקציה &nbsp;{showIcon && <FaDownload />}</StyledButton>
    )
}

const StyledButton = styled.button`
    padding: 15px 20px;
    font-size: 20px;
    background-color: #08C988;
    color: #fff;
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 1rem;
    margin: 0 auto;
    display: block;

  &:hover {
    background-color: #027951;
  }
`;
