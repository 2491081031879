import React, { useState, useEffect } from "react";
import styled from "styled-components";
import homeImage from "../assets/home.jpg";
import googlePlay from "../assets/google_play.png";
import appStore from "../assets/download_ios.png";
import { GiFootprint } from "react-icons/gi";
import { IoMdImages } from "react-icons/io";
import { RiHandCoinLine } from "react-icons/ri";
import { BsLinkedin, BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { FiMail } from "react-icons/fi";
import SignUpModal from "../components/register/SignUpModal";
import DownloadAppBtn from "../components/DownloadAppBtn";

export default function LandingPage() {
  const [regiser, setRegiser] = useState(false);

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (!isMobile) {
      window.location.href = '/';
      return;
    }
  }, [])

  const urlParams = new URLSearchParams(window.location.search);
  const platformParam = urlParams.get('platform');
  const platform = platformParam == 'TikTok' ? 'TikTokLink' : platformParam == 'Facebook' ? 'FacebookLink' : '';

  if (regiser) {
    return <SignUpModal dismiss={() => setRegiser(false)} />
  }

  const TikTokWrapper = (BTN) => (
    <div className="TikTokWrapper" style={{ width: "100%" }}>
      {BTN}
    </div>
  )

  return (
    <Section>

      <div className="mainImage" style={{ backgroundImage: `url(${homeImage})` }} />

      <div className="content">
        <div className="title">
          <h1 style={{ color: "white", paddingBottom: "20px" }}>Tripic</h1>
          <h2>הטיול הבא שלך</h2>
          <h2>מתחיל כאן</h2>
        </div>

        <div style={{ width: "100%" }}>
          {platformParam == 'TikTok' ? TikTokWrapper(<DownloadAppBtn classEvent={platform} />) : <DownloadAppBtn classEvent={platform} />}
        </div>
      </div>

      <div className="footerImage" />


      <div style={{ backgroundColor: "#f0f2f6", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div className="card">
          <h3>
            אוהבים לטייל?
            הכירו את Tripic!
          </h3>
          <h3>רשת חברתית למטיילים</h3>
          <br />

          <h4>האפליקציה היחידה שבה הטיולים שלכם שווים כסף!</h4>

          <div style={{ margin: 15 }}>
            <h4 style={{ marginBottom: 10 }}>איך זה עובד?</h4>

            <div className="rowItem">
              <GiFootprint color="#2e2e2e" style={{ transform: "scaleX(-1)", marginInline: 5 }} size={22} />
              <span>מטיילים</span>
            </div>

            <div className="rowItem">
              <IoMdImages color="#2e2e2e" size={22} style={{ marginInline: 5 }} />
              <span>משתפים</span>
            </div>
            <div className="rowItem">
              <RiHandCoinLine color="#2e2e2e" size={22} style={{ marginInline: 5 }} />
              <span style={{ fontWeight: "bold" }}>מרווחים!</span>
            </div>
          </div>

          <h4>  כל טיול שווה לכם מגוון הטבות!</h4>
          <br />

          <h3>רוצים לזכות בטיסה במצנח רחיפה במתנה?</h3>

          <p>🔹 הורידו את האפליקציה שלנו .</p>
          <p>🔹 היכנסו לאפליקציה ולחצו על "שיתוף חברים". </p>

          <a href="https://www.shahafm.co.il/" target="_blank">
            מתנת <bold>שחף מצנחי רחיפה</bold>
          </a>
          <span>***הזוכה יוכרז ב- 31.8.2023***</span>

          <h3>
            הורידו את האפליקציה עכשיו!
          </h3>
        </div>

      </div>

      <div>
        <h2 style={{ textAlign: "center", margin: 20 }}>להורדת האפליקציה</h2>
        {
          platformParam == 'TikTok' ? TikTokWrapper(
            <div className="btnsDownloadWrapper">
              <a className={platform} href="https://play.google.com/store/apps/details?id=com.goldenapps.tripic&fbclid=IwAR0PHMI7fxsfh0wiliUpzjMXYGIm7O1mvtMfWrVwxgdjb2ZhFeAF3gh8c-o" target="_blank">
                <img src={googlePlay} />
              </a>

              <a className={platform} href="https://apps.apple.com/app/tripic-travelers-place/id1659214818?fbclid=IwAR3JiVWqAwqn5Vh01c09Bhn6JNF4VzbZqXS-lREBK_F9E8H1W--JTqIbVbI" target="_blank">
                <img src={appStore} />
              </a>
            </div>
          ) :
            <div className="btnsDownloadWrapper">
              <a className={platform} href="https://play.google.com/store/apps/details?id=com.goldenapps.tripic&fbclid=IwAR0PHMI7fxsfh0wiliUpzjMXYGIm7O1mvtMfWrVwxgdjb2ZhFeAF3gh8c-o" target="_blank">
                <img src={googlePlay} />
              </a>
              <a className={platform} href="https://apps.apple.com/app/tripic-travelers-place/id1659214818?fbclid=IwAR3JiVWqAwqn5Vh01c09Bhn6JNF4VzbZqXS-lREBK_F9E8H1W--JTqIbVbI" target="_blank">
                <img src={appStore} />
              </a>
            </div>
        }
      </div>

      <div className="footer">
        <a href="https://www.facebook.com/profile.php?id=100086864614793" target="_blank">
          <BsFacebook color="#2e2e2e" size={25} />
        </a>

        <a href="https://instagram.com/tripic_israel?igshid=ZDdkNTZiNTM=" target="_blank">
          <AiFillInstagram color="#2e2e2e" size={26} />
        </a>

        <a href="mailto: trippic.go@gmail.com" target="_blank">
          <FiMail color="#2e2e2e" size={25} />
        </a>
      </div>
    </Section>
  );
}

const Section = styled.section`
 height: 100%;
 margin: 0;
.bg {
  /* The image used */
  background-image: url(homeImage);

  /* Full height */
  height: 100%; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer{
  display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 40px;
    padding-bottom: 20px;
    background-color: "white"
}
.footerImage{
  position: absolute;
    bottom: 0;
    background-image: linear-gradient(transparent, black);
    opacity: 0.8;
    width: 100%;
    height: 20%;
    overflow: hidden;
}

.rowItem{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
  .card {
    background-color:white;
      margin: 20px;
      padding: 20px;
      border-radius: 20px;
      display: inline-block;
      text-align: center;
      a {
        display: block;
        padding: 0.5rem 1rem;
        cursor: pointer;
        color: #2ba39b;
        font-weight:bold;
        font-size: 1.1rem;
        text-decoration:underline;
    }
  }
  
  .mainImage {
      height: 100%; 
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      filter: brightness(50%);
    }
  
  
    button {
      cursor: pointer;
      border-radius: 1.5rem;
      border: none;
      width: 100%;
      height: 50px;
      font-size: 1.1rem;
      font-weight: bold;
  }

  .btnsDownloadWrapper {
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
    img{
      width: 150px;
      height: 50px;
      object-fit: fill;
    }
  } 


  .btnsWrapper {
    flex-direction: column;
    display: flex;
    img{
      width: 100%;
      height: 100% ;
    }
  } 
  .content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    padding: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    .title {
      color: white;
      h2{

      }
      h1 {
        font-size: 3rem;
        letter-spacing: 0.2rem;

      }
      p {
        text-align: center;
        padding: 0 30vw;
        margin-top: 0.5rem;
        font-size: 1.2rem;
      }
    }
  }

  @media screen and (min-width: 280px) and (max-width: 980px) {
    /* height: 33rem;
    .background {
      img {
        height: 100%;
        object-fit: cover;
        border-radius: 20px
      }
    }

    .btnsWrapper {
      flex-direction: column;
  }  */


    .content {
      .title {
        text-shadow: 2px 2px #191818;
        h1 {
          font-size:3rem;
        }
        h2{
          font-size:2rem;
        }
        p {
          font-size: 1rem;
          padding: 1vw;
        }
      }
    }
  }
`;
