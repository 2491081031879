import React from "react";
import styled from "styled-components";
import { BsLinkedin, BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { FiMail } from "react-icons/fi";

export default function Footer() {
  return (
    <FooterContainer>
      <span>Copyright &copy; 2023 Tripic. All rights reserved</span>

      <ul>
        <li>
          <a href="#home" >
            דף הבית
          </a>
        </li>
        <li>
          <a href="#recommend" >
            מומלצים
          </a>
        </li>
        <li>
          <a href="#community" >
            קהילה
          </a>
        </li>
        <li>
          <a href="#video" >
            סרטון שיווק
          </a>
        </li>
      </ul>

      <ul className="social__links">
        <li>
          <a href="https://www.facebook.com/profile.php?id=100086864614793" target="_blank">
            <BsFacebook />
          </a>
        </li>
        <li>
          <a href="https://instagram.com/tripic_israel?igshid=ZDdkNTZiNTM=" target="_blank">
            <AiFillInstagram />
          </a>
        </li>
        <li>
          <a href="mailto: trippic.go@gmail.com" target="_blank">
            <FiMail />
          </a>
        </li>
      </ul>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-evenly;
  background-color: #d0d8ff;
  border-radius: 0.5rem;
  padding: 2.5rem;
  margin-top: 20px;
  
  ul {
    display: flex;
    list-style-type: none;
    gap: 2rem;
    li {
      a {
        text-decoration: none;
        color: black;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #302ce9;
        }
      }
      svg {
        font-size: 1.3rem;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #302ce9;
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1024px) {
    flex-direction: column;
    gap: 2rem;
    ul {
      flex-direction: column;
    }
    .social__links {
      flex-direction: row;
    }
  }
`;
