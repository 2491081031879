import React from "react";
import styled from "styled-components";
import beachImage from "../assets/hero.png";
import homeImage from "../assets/home.jpg";
import { BsGooglePlay, BsApple } from "react-icons/bs";
import DownloadApp from "./DownloadApp";
import DownloadAppBtn from "./DownloadAppBtn";

export default function Hero() {
  return (
    <Section id="home">
      <div className="background" />

      <div className="content">
        <div className="title">
          <h2>הטיול הבא שלך</h2>
          <h2>מתחיל כאן</h2>
        </div>

        <div className="downloadBtn">
          <DownloadAppBtn style={{ backgroundColor: 'white', color: 'black' }} />
        </div>

        <div className="downloadBtnsWrapper">
          <h2 style={{ color: "white" }}>להורדת האפליקציה</h2>
          <div className="btnsWrapper">
            <a href="https://play.google.com/store/apps/details?id=com.goldenapps.tripic&fbclid=IwAR0PHMI7fxsfh0wiliUpzjMXYGIm7O1mvtMfWrVwxgdjb2ZhFeAF3gh8c-o" target="_blank">
              Google Play
              <BsGooglePlay style={{ margin: 5 }} />
            </a>
            <a href="https://apps.apple.com/app/tripic-travelers-place/id1659214818?fbclid=IwAR3JiVWqAwqn5Vh01c09Bhn6JNF4VzbZqXS-lREBK_F9E8H1W--JTqIbVbI" target="_blank">
              App store
              <BsApple style={{ margin: 5 }} />
            </a>
          </div>
        </div>
      </div>

    </Section>
  );
}

const Section = styled.section`
  position: relative;
  margin-top: 2rem;
  width: 100%;
  height: 33rem;

  button {
      cursor: pointer;
      border-radius: 1.5rem;
      border: none;
      width: 100%;
      height: 50px;
      padding: 10px 30px;
      font-size: 1.1rem;
      font-weight: bold;
  }
  
  .downloadBtn{
    display: none
  }

  .background {
    height: 100%;
      background-image: url(${beachImage});
      border-radius: 20px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      filter: brightness(60%);
  }

  .btnsWrapper {
 //   background-color: #ffffffce;
      padding: 0.5rem;
      border-radius: 0.5rem;
      flex-direction: row;
       display: flex;
        a {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        cursor: pointer;
        border-radius: 0.5rem;
        border: none;
        color: black;
        background-color: white;
        text-decoration:none;
        /* background-color: #4361ee; */
        margin: 5px;
        font-size: 1.1rem;
        letter-spacing: 0.1rem;
        transition: 0.3s ease-in-out;
        &:hover {
          background-color:  #023e8a;
        }
          }
  } 

  .content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    padding: 50px 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    .title {
      color: white;
      h2{
        font-size: 2.5rem;
      }
      h1 {
        font-size: 3rem;
        letter-spacing: 0.2rem;

      }
      p {
        text-align: center;
        padding: 0 30vw;
        margin-top: 0.5rem;
        font-size: 1.2rem;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 980px) {
    height: 33rem;

    .background {
      background-image: url(${homeImage});
      border-radius: 20px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      filter: brightness(60%);
  }
    /* .background {
      img {
        height: 100%;
        object-fit: cover;
        border-radius: 20px
      }
    } */

    .btnsWrapper {
      flex-direction: column;
  } 

  .downloadBtnsWrapper{
    display: none;
  }

  .downloadBtn{
    display: block
  }


  .content {
      .title {
        text-shadow: 2px 2px #191818;
        h1 {
          font-size:3rem;
        }
        h2{
          font-size:1.5rem;
        }
        p {
          font-size: 1rem;
          padding: 1vw;
        }
      }
    }
  }
`;
