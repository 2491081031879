import React, { useState } from "react";
import styled from "styled-components";
import { FaDownload } from "react-icons/fa";
import { BsGooglePlay, BsApple } from "react-icons/bs";

export default function DownloadApp({ children, style }) {

  const [showBtns, setShowBtns] = useState(false);

  return (
    <Section onClick={() => setShowBtns(true)}>
      <div className="service">
        {children}
        <div className="downloadSection">
          <div className="icon">
            <FaDownload />
          </div>
          <h3>להורדת האפליקציה</h3>
        </div>

        {
          showBtns &&
          <div className="btnsWrapper">
            <a href="https://play.google.com/store/apps/details?id=com.goldenapps.tripic&fbclid=IwAR0PHMI7fxsfh0wiliUpzjMXYGIm7O1mvtMfWrVwxgdjb2ZhFeAF3gh8c-o" target="_blank">
              Google Play
              <BsGooglePlay style={{ margin: 5 }} />
            </a>
            <a href="https://apps.apple.com/app/tripic-travelers-place/id1659214818?fbclid=IwAR3JiVWqAwqn5Vh01c09Bhn6JNF4VzbZqXS-lREBK_F9E8H1W--JTqIbVbI" target="_blank">
              App store
              <BsApple style={{ margin: 5 }} />
            </a>
          </div>
        }

      </div>
    </Section>
  );
}

const Section = styled.section`
  gap: 1rem;
  .service {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    background-color: aliceblue;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    &:hover {
      transform: translateX(0.4rem) translateY(-1rem);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .icon {
      img {
        height: 2.4rem;
      }
    }
  }

  .downloadSection {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .btnsWrapper {
 //   background-color: #ffffffce;
      padding: 0.5rem;
      border-radius: 0.5rem;
      flex-direction: row;
    display: flex;
  } 
  a {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 0.5rem;
    border: none;
    color: black;
    background-color: white;
    text-decoration:none;
    /* background-color: #4361ee; */
    margin: 5px;
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
    transition: 0.3s ease-in-out;
    &:hover {
      background-color:  #023e8a;
    }
      }
  @media screen and (min-width: 280px) and (max-width: 720px) {
    .btnsWrapper {
      flex-direction: column;
  } 
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
