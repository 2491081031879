import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import scrollreveal from "scrollreveal";
import LandingPage from "./screens/LandingPage";
import Main from "./screens/Main";

export default function App() {
  const [showLandigPage, setShowLandingPage] = useState(false)

  useEffect(() => {
    const sr = scrollreveal({
      origin: "top",
      distance: "80px",
      duration: 2000,
      reset: true,
    });

    sr.reveal(` nav,#hero, #services,#recommend, #testimonials,footer `,
      { opacity: 0, interval: 300, });

    const urlParams = new URLSearchParams(window.location.search);
    const registerParam = urlParams.get('register');
    const isRegister = window.location.pathname.includes('register');

    if (registerParam || isRegister) {
      setShowLandingPage(true)
    }
  }, []);

  return (
    showLandigPage ? <LandingPage /> : <Main />
  )

  // return (
  //   <Router>
  //     <Routes>
  //       <Route path="/register" element={<LandingPage />} />
  //       <Route path="/" element={<Main />} />
  //     </Routes>
  //   </Router>
  // );
}
