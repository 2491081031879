import React, { useState } from 'react'
import styled from "styled-components";
import DownloadAppBtn from '../DownloadAppBtn';

export default function SignUpModal({ dismiss }) {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [allowMarketingMails, setAllowMarketingMails] = useState(true);

    const [stage, setStage] = useState(1);

    const register = async () => {
        const lettersAndSpace = /^[\p{L}\-'\. ]+([\p{L}\-'\. ]+)*$/u;
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailPattern.test(email)) {
            return alert("כתובת אימייל לא תקינה")
        }

        if (fullName.length < 2) {
            return alert("שם מלא צריך להכיל לפחות 3 תווים")
        }

        if (!lettersAndSpace.test(fullName)) {
            return alert("ניתן להזין אותיות בלבד")
        }

        if (password < 5) {
            return alert("סיסמה צריכה להכיל לפחות 5 תווים")
        }

        const { firstName, lastName } = splitFullName(fullName);

        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password, firstName, lastName, allowMarketingMails }),
            };

            // const response = await fetch('http://localhost:19003/api/website/register', requestOptions);

            const response = await fetch('https://68.183.120.212/api/website/register', requestOptions, { rejectUnauthorized: false });

            if (response.status == 400) {
                alert("משתמש רשום")
            }
            else if (response.status == 201) {
                setStage(2)
            }
            else {
                alert("שגיאה בשרת, אנא נסו שוב מאוחר יותר")
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    function splitFullName(fullName) {
        const words = fullName.trim().split(/\s+/);
        if (words.length === 1) {
            const firstName = words[0];
            return { firstName, lastName: "  " };
        } else {
            const firstName = words.shift();
            let lastName = words.join(' ');
            lastName = lastName.length == 1 ? lastName + ' ' : lastName;
            return { firstName, lastName };
        }
    }

    const onDownloadApp = () => {
        // TODO: ADD fbq('track', 'Lid');
    }

    const continueHandler = () => {
        if (stage == 1) {
            register()
        }
    }

    return (
        <Section>
            <div className="modal-overlay">
                <div className="modal">
                    <span className="close" onClick={dismiss}>&times;</span>
                    <div className="modal-content">

                        <div style={{ marginBottom: 30 }}>
                            <h2>ברוכים הבאים</h2>
                            <h3>תהליך הרשמה קצר ומסיימים</h3>
                        </div>

                        <div>
                            {
                                stage == 1 &&
                                <>
                                    <div className='inputWrapper'>
                                        <p>שם מלא</p>
                                        <input type='text' value={fullName} onChange={(event) => setFullName(event.target.value)} />
                                    </div>

                                    <div className='inputWrapper'>
                                        <p> כתובת מייל</p>
                                        <input type='email' value={email} onChange={(event) => setEmail(event.target.value)} />
                                    </div>

                                    <div className='inputWrapper'>
                                        <p>סיסמה</p>
                                        <input type='text' value={password} onChange={(event) => setPassword(event.target.value)} />
                                    </div>

                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <input
                                            type="checkbox"
                                            style={{ margin: 5, accentColor: "gray" }}
                                            checked={allowMarketingMails}
                                            onChange={(event) => setAllowMarketingMails(event.target.checked)}
                                        />
                                        <span style={{ fontSize: 13 }}>אני מאשר/ת קבלת דיוור על עדכונים הטבות ומבצעים</span>
                                    </div>
                                    <div style={{ textAlign: "center", marginTop: 50 }}>
                                        <button onClick={continueHandler}>המשך</button>
                                    </div>
                                </>
                            }

                            {
                                stage == 2 &&
                                <div>
                                    <p>
                                        תהליך ההרשמה הושלם בהצלחה. <br />
                                        עכשיו נותר רק לקפוץ לצעד הבא כדי לקבל הזדמנות לזכות בלילה בצימר מפנק במתנה!<br />
                                        מאות יעדים מומלצים לטייל, הטבות הנחות ומתנות ייחודיות מחכים לך באפליקציה.<br />
                                        כל מה שנשאר לך לעשות הוא להקיש על הורדת האפליקציה ולהתחבר עם הפרטים שבהם נרשמת.
                                    </p>
                                    <div style={{ textAlign: "center", marginTop: 50 }}>
                                        <DownloadAppBtn />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}

const Section = styled.section`

button {
  background-color: #000;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 20px;
}


.inputWrapper{
    margin-bottom: 50px;
    input{
        padding: 15px;
        width: 90%;
        font-size: 20px;
    }
}



.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 9999; /* Ensure the modal appears on top of other content */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  background-color:#f0f2f6
}

.modal-content {
    display: flex;
    flex-direction: column;
  //  justify-content: space-between;
    height: 100%;
    padding: 20px;
}

`;