import React from 'react'
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import Recommend from '../components/Recommend';
import CommunityBanner from '../components/Community';
import Video from '../components/Video';
import Footer from '../components/Footer';
import Gift from '../components/Gift';
import Services from '../components/Services';
import DownloadAppBtn from '../components/DownloadAppBtn';

export default function Main() {
    return (
        <div style={{ margin: '1rem 2rem', position: 'relative' }}>
            <Gift />
            <Navbar />
            <Hero />
            <Services />

            <Recommend />
            <CommunityBanner />

            <Video />
            <Footer />
        </div>
    )
}
