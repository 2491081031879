import React from "react";
import styled from "styled-components";
import CommunityBanner from "../assets/CommunityBanner.jpg";
import { BsFacebook } from "react-icons/bs";

export default function Community() {
  return (
    <Section id="community">
      <div className="container">
        <img src={CommunityBanner} alt="" />
        <div className="desc">
          <div className="content">
            <h1>הקהילה שלנו</h1>
            <p>
              בקהילה שלנו, אנו משתפים ביחד מקומות לטייל, המלצות, טיפים וחוויות מהשטח, כדי להכיר ולחוות את הארץ והעולם בצורה טובה יותר.
            </p>
            <p>הצטרפו לקהילת המטיילים שלנו בפייסבוק וקבלו עדכנים על מבצעים הטבות והגרלות ייחודיות לחברי הקהילה</p>
            <a href="https://www.facebook.com/groups/tripic/" target="_blank">
              Facebook
              <BsFacebook style={{ margin: 5 }} />
            </a>
          </div>

        </div>
      </div>
    </Section>
  );
}

const Section = styled.section`
  padding: 2rem 0;

  .desc{
    text-align: center;
    margin: 20px;
  }
  a {
    align-items: center;
    display: flex;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 1rem;
    border: none;
    color: white;
    background-color:  #023e8a;
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
 //   text-transform: uppercase;
    transition: 0.3s ease-in-out;
    &:hover {
      background-color: #48cae4;
    }
  }
  .container {
    width: 60%;
    margin: 0px auto;
    padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      background-color:#f0f8ff;
      border-radius: 1rem;
      transition: 0.3s ease-in-out;
      &:hover {
        transform: translateX(0.4rem) translateY(-1rem);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    img {
      width: 100%;
      height: 550px;
      object-fit: cover;
      border-radius: 5px;
    }
  }
  .content {
    height: 100%;
    width: 100%;
  //  position: absolute;
   // top: 0;
    z-index: 3;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

      h1 {
        font-size: 3rem;
        letter-spacing: 0.2rem;
      }
      p {
        font-size: 1.2rem;
      }
    
    .search {
      display: flex;
      background-color: #ffffffce;
      padding: 0.5rem;
      border-radius: 0.5rem;
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 1.5rem;

        label {
          font-size: 1.1rem;
          color: #03045e;
        }
        input {
          background-color: transparent;
          border: none;
          text-align: center;
          color: black;
          &[type="date"] {
            padding-left: 3rem;
          }

          &::placeholder {
            color: black;
          }
          &:focus {
            outline: none;
          }
        }
      }
      button {
        padding: 1rem;
        cursor: pointer;
        border-radius: 0.3rem;
        border: none;
        color: white;
        background-color: #4361ee;
        font-size: 1.1rem;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        &:hover {
          background-color: #023e8a;
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 980px) {
   // height: 25rem;
    .container {
      width: 100%;
    img {
      width: 100%;
      height: auto
    }
  }

    .background {
      background-color: palegreen;
      img {
        height: 100%;
      }
    }
    .content {
      .title {
        h1 {
          font-size: 1rem;
        }
        p {
          font-size: 0.8rem;
          padding: 1vw;
        }
      }
      .search {
        flex-direction: column;
        padding: 0.8rem;
        gap: 0.8rem;
        /* padding: 0; */
        .container {
          padding: 0 0.8rem;
          input[type="date"] {
            padding-left: 1rem;
          }
        }
        button {
          padding: 1rem;
          font-size: 1rem;
        }
        /* display: none; */
      }
    }
  }
`;
